import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types'
import { create } from 'zustand'

export type PdfFileStateProps = {
  pdfFile?: File
  numPages: number | null
  pageSelected: number
  isLoading: boolean
}

interface PdfFileProps extends PdfFileStateProps {
  onDocumentLoadSuccess: (props: DocumentCallback) => void
  hanldeSetNumPages: (numPages: number) => void
  handleSetPdfFile: (pdfFile: string) => void
  changePage: (page: number) => void
  handleSetPdfFileFromLayout: (pdfFile: File) => void
}

export const convertBase64ToPdf = (base64: string) => {
  // Convert base64 string to binary data
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // Create a Blob from the binary data
  const blob = new Blob([byteArray], { type: 'application/pdf' })

  // Create a File from the Blob
  return new File([blob], 'training-pdf', { type: 'application/pdf' })
}

export const usePdfFile = create<PdfFileProps>((set) => ({
  pdfFile: undefined,
  numPages: null,
  isLoading: true,
  pageSelected: 1,
  onDocumentLoadSuccess: (props) => {
    set({ numPages: props.numPages, isLoading: false })
  },

  hanldeSetNumPages: (numPages) => {
    set({ numPages })
  },

  handleSetPdfFile: (pdfFile) => {
    const file = convertBase64ToPdf(pdfFile)
    set({ pdfFile: file })
  },

  changePage: (page: number) => {
    set({ pageSelected: page })
  },

  handleSetPdfFileFromLayout: (pdfFile: File) => {
    set({  pdfFile })
  },
}))
