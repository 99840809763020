import {
  ContainerDataProvider,
  EnvironmentProvider,
  EnvConfig,
  I18nProvider,
  ThemeProvider,
} from '@providers'
import { ModalPocProvider } from 'context/modalContext'
import { UploadFileProvider } from 'providers/UploadFileProvider'
import { PropsWithChildren } from 'react'
import { ToastContainer, Zoom } from 'react-toastify'

export function GlobalProvider({ children, ...props }: PropsWithChildren<EnvConfig>) {
  return (
    <ThemeProvider>
      <ToastContainer newestOnTop limit={3} theme="colored" transition={Zoom} autoClose={3500} style={{ marginTop: 30 }} />
      <EnvironmentProvider env={props}>
        <I18nProvider>
          <ModalPocProvider>
            <UploadFileProvider>
              <ContainerDataProvider>{children}</ContainerDataProvider>
            </UploadFileProvider>
          </ModalPocProvider>
        </I18nProvider>
      </EnvironmentProvider>
    </ThemeProvider>
  )
}
