import {
  ContainerDataProvider,
  EnvironmentProvider,
  EnvConfig,
  I18nProvider,
  ThemeProvider,
} from '@providers'
import { ModalPocProvider } from 'context/modalContext'
import { UploadFileProvider } from 'providers/UploadFileProvider'
import { PropsWithChildren } from 'react'
import { tw } from 'twind'
import { ToastContainer, Zoom } from 'react-toastify'

export function GlobalProvider({ children, ...props }: PropsWithChildren<EnvConfig>) {
  return (
    <ThemeProvider>
      <ToastContainer newestOnTop limit={3} transition={Zoom} autoClose={3500} theme="light" bodyClassName={tw(`flex items-start`)} toastClassName={tw(`shadow-sm border-t border-l border-r border-[#f8f8f8]`)} style={{ marginTop: 30 }} />
      <EnvironmentProvider env={props}>
        <I18nProvider>
          <ModalPocProvider>
            <UploadFileProvider>
              <ContainerDataProvider>{children}</ContainerDataProvider>
            </UploadFileProvider>
          </ModalPocProvider>
        </I18nProvider>
      </EnvironmentProvider>
    </ThemeProvider>
  )
}
